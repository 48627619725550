import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Axios from 'axios';

import uuid from 'uuid';

import Todos from './components/Todos';
import AddTodo from './components/AddTodo';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import About from './components/layout/About';

import './App.css';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

class App extends Component {
  state = {
    todos: [
      {
        id: 1,
        title: 'Fazer trabalho da Hilda nº 1403',
        completed: false,
      },
      {
        id: 2,
        title: 'Ler livro de SOM',
        completed: true,
      },
    ]
  }

  componentDidMount() {
    Axios.get('https://jsonplaceholder.typicode.com/todos?_limit=10')
      .then(resp => {
        this.setState({ todos: resp.data });
      });
  }

  markComplete = (id) => {
    this.setState({ todos: this.state.todos.map(todo => {
      console.log(id);
      

      if (todo.id === id) {
        todo.completed = !todo.completed;
      }

      return todo;
    }) });
  }

  addTodo = (title) => {
    const newTodo = {
      id: uuid.v4(),
      title: title,
      completed: false,
    }

    Axios.post('https://jsonplaceholder.typicode.com/todos', {
      title: title,
      completed: false,
    }).then(resp => {
      this.setState({ todos: [...this.state.todos, newTodo] });
    })
  }

  delItem = (id) => {
    Axios.delete(`https://jsonplaceholder.typicode.com/todos/${id}`).then(resp => {
      this.setState({ todos: [...this.state.todos.filter(todo => todo.id !== id)] });
    });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Route exact path="/" render={props => (
            <>
              <AddTodo addTodo={this.addTodo} />
              <Todos todos={this.state.todos} markComplete={this.markComplete} delItem={this.delItem} />
              <Footer />
            </>
          )} />

          <Route path="/about" component={About} />
        </div>
      </Router>
    );
  }
}

export default App;
